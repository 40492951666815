import CompanyListerTable from './CompanyListerTable.vue'
import CompanyDataTable from './CompanyDataTable.vue'
import CompanySearchLister from './CompanySearchLister.vue'
import CompanyBoxes from './CompanyBoxes.vue'

const components = {
    CompanyListerTable,
    CompanyDataTable,
    CompanyBoxes,
    CompanySearchLister,
}

export default {
    install(Vue, settings = {}) {
        const { 
            companyListerMixin,
        } = settings
        let headerAddons = {}

        if (typeof window.wisenetwork !== 'undefined'
            && Array.isArray(window.wisenetwork.companyListerCustomFields)) {
            window.wisenetwork.companyListerCustomFields.forEach(
                row => {
                    headerAddons[row.id] = row.text;
                }
            )
        }
        
        Vue.prototype.$_COMPANY_LISTER_MODES = {
            table: 0,
            imageBox: 1,
            titleBox: 2,
            dataTable: 3,
            imageDescriptionBox: 4,
            searchLister: 5,
        }
        Vue.prototype.$_COMPANY_LISTER_HEADERS = {
            logo: _locale('logo').ucfirst(),
            company: _locale('company').ucfirst(),
            industry: _locale('industry').ucfirst(),
            address: _locale('address').ucfirst(),
            description: _locale('description').ucfirst(),
            city: _locale('municipality').ucfirst(),
            'postal code': _locale('postal code').ucfirst(),
            internet: _locale('internet').ucfirst(),
            auxiliaryBusinessName: _locale('auxiliary business name').ucfirst(),
            exportCountries: _locale('export countries').ucfirst(),
            ...headerAddons,
        }
        
        Object.keys(components).forEach((name) => {
            const component = components[name];

            if (name === 'CompanySearchLister' &&
                typeof companyListerMixin !== 'undefined') {
                component.mixins = [ companyListerMixin ]
            }
    
            Vue.component(name, component);    
        })
    }
}