
<div>
    <div>
        <div class="pull-right" style="margin-bottom: 7px;">
            <input type="text" 
                v-model="search" 
                :placeholder="$_locale('search')"
                class="form-control" />
        </div>
    </div>
    <wn-table-pages 
        :items="sortedRows" 
        :perPage="25"
        :pagingOptions="[25, 50, -1]"
        :sortTrigger="`${ currentSortDir }-${ currentSort }`">
        
        <template #head>
            <th v-for="(heading, index) in headings" 
                :key="'th-'+index">
                <wn-table-header-cell 
                    @click="setSort(heading)" 
                    v-bind="{ direction: currentSortDir, isActive: currentSort === key }">
                    {{ headingText(heading) }}
                </wn-table-header-cell>
            </th>
        </template>

        <template v-slot="{ item: row, index }">
            <tr :key="'row-'+row.$_rowId">
                <template v-for="(heading, index) in headings">
                    <td v-if="heading === 'logo'"
                        :key="'td-'+row.$_rowId+'-'+index"
                        :data-title="headingText(heading)">
                        <component class="logo-outer"
                            :is="row.url ? 'a' : 'div'"
                            :href="row.url"
                            :target="row.url ? '_blank' : ''">
                            <div class="logo-inner" 
                                :style="{
                                    'background': `url(${ row.serviceUrl }) center center / cover no-repeat rgb(255,255,255)`,
                                }">
                            </div>
                        </component>
                    </td>
                    <td v-else
                        :key="'td-'+row.$_rowId+'-'+index"
                        :data-title="headingText(heading)"
                        v-html="row[heading]">
                    </td>
                </template>
            </tr>
            <tr class="description-row" v-if="showDescription"
                :key="'row-description-'+row.$_rowId">
                <td :colspan="headings.length"
                    :data-title="headingText('description')">{{ row.description }}</td>
            </tr>
        </template>

    </wn-table-pages>
</div>
