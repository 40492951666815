
<div class="table-responsive">
    <table class="cke_show_border table table-hover">
        <tbody>
            <tr>
                <th v-for="(heading, index) in headings" 
                    :key="'th-'+index">
                    {{ headingText(heading) }}
                </th>
            </tr>
            <template v-for="(row, index) in rows">
                <tr :key="'row-'+row.$_rowId">
                    <template v-for="(heading, index) in headings">
                        <td v-if="heading === 'logo'"
                            :key="'td-'+row.$_rowId+'-'+index"
                            :data-title="headingText(heading)">
                            <component class="logo-outer"
                                :is="row.url ? 'a' : 'div'"
                                :href="row.url"
                                :target="row.url ? '_blank' : ''">
                                <div class="logo-inner" 
                                    :style="{
                                        'background': `url(${ row.serviceUrl }) center center / cover no-repeat rgb(255,255,255)`,
                                    }">
                                </div>
                            </component>
                        </td>
                        <td v-else
                            :key="'td-'+row.$_rowId+'-'+index"
                            :data-title="headingText(heading)"
                            v-html="row[heading]">
                        </td>
                    </template>
                </tr>
                <tr class="description-row" v-if="showDescription"
                    :key="'row-description-'+row.$_rowId">
                    <td :colspan="headings.length"
                    :data-title="headingText('description')">{{ row.description }}</td>
                </tr>
            </template>
        </tbody>
    </table>
</div>
