
<div class="row">
    <template v-for="(row, index) in rows">
        <div 
            :key="'row-'+row.$_rowId"
            :class="colClass">
            <component :class="boxClass(row)"
                :style="boxStyle(row)"
                :is="row.url ? 'a' : 'div'"
                :href="row.url"
                :target="row.url ? '_blank' : ''">
                <template v-if="mode === $_COMPANY_LISTER_MODES.imageBox && !row.serviceUrl">
                    {{ row.auxiliaryBusinessName ? row.auxiliaryBusinessName : row.company }}
                </template>
                <template v-else-if="mode === $_COMPANY_LISTER_MODES.imageDescriptionBox">
                    <img v-if="row.serviceUrl" :src="row.serviceUrl" class="img-responsive" />
                    <template v-else>
                        {{ row.auxiliaryBusinessName ? row.auxiliaryBusinessName : row.company }}
                    </template>
                    <div :style="{
                            'text-align': 'left',
                            'font-size': '14px',
                            padding: '5px 10px'
                        }"
                        :title="row.description">
                        <p class="company-description hidden-xs">
                            {{ row.description.length > maxLength ? row.description.substring(0, maxLength - 3) + "..." : row.description }}
                        </p>
                        <p class="company-description hidden-sm hidden-md hidden-lg">
                            {{ row.description }}
                        </p>
                    </div>                
                </template>
                <template v-else-if="mode === $_COMPANY_LISTER_MODES.titleBox">
                    {{ row.auxiliaryBusinessName ? row.auxiliaryBusinessName : row.company }}
                </template>
            </component>
        </div>
        <div v-if="mode === $_COMPANY_LISTER_MODES.titleBox && (index + 1) % 4 == 0" class="clearfix hidden-xs hidden-sm"></div>
    </template>
</div>
