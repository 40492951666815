<template>
<div class="company-lister-master">
    <div class="company-lister-search">
        <div class="company-lister-search-title bg-primary">{{ $_locale('Search') }}</div>
        <div class="company-lister-search-inputs">
            <div v-for="(heading, index) in searchHeadings" 
                :key="index">
                <h6>{{ headingText(heading) }}</h6>
                <input type="text" class="form-control"
                    v-model="searchArgs[heading]">
            </div>
        </div>
    </div>
    <template v-if="hasSearch === false">
        <h5>{{ $_locale('search for companies with keywords') }}</h5>
    </template>
    <template v-else-if="hasSearch && sortedRows.length === 0">
        <h5>{{ $_locale('no companies found') }}</h5>
    </template>
    <wn-table-pages v-else
        :items="sortedRows" 
        :perPage="25"
        :pagingOptions="[25, 50, -1]"
        :sortTrigger="`${ currentSortDir }-${ currentSort }`">
        
        <template #head>
            <th v-for="(heading, index) in headings" 
                :key="'th-'+index">
                <wn-table-header-cell 
                    @click="setSort(heading)" 
                    v-bind="{ direction: currentSortDir, isActive: currentSort === key }">
                    {{ headingText(heading) }}
                </wn-table-header-cell>
            </th>
        </template>

        <template v-slot="{ item: row, index }">
            <tr :key="'row-'+row.$_rowId">
                <template v-for="(heading, index) in headings">
                    <td v-if="heading === 'logo'"
                        :key="'td-'+row.$_rowId+'-'+index"
                        :data-title="headingText(heading)">
                        <component class="logo-outer"
                            :is="row.url ? 'a' : 'div'"
                            :href="row.url"
                            :target="row.url ? '_blank' : ''">
                            <div class="logo-inner" 
                                :style="{
                                    'background': `url(${ row.serviceUrl }) center center / cover no-repeat rgb(255,255,255)`,
                                }">
                            </div>
                        </component>
                    </td>
                    <td v-else
                        :key="'td-'+row.$_rowId+'-'+index"
                        :data-title="headingText(heading)"
                        v-html="row[heading]">
                    </td>
                </template>
            </tr>
            <tr class="description-row" v-if="showDescription"
                :key="'row-description-'+row.$_rowId">
                <td :colspan="headings.length"
                    :data-title="headingText('description')">{{ row.description }}</td>
            </tr>
        </template>

    </wn-table-pages>
</div>
</template>
    
<script>
export default {
    props: {
        headings: {
            type: Array,
            required: true,
        },
        searchHeadings: {
            type: Array,
            required: false,
            default: () => ['company']
        },
        showDescription: {
            type: Boolean,
            default: false,
        },
        rows: {
            type: Array,
            required: true,
        },
    },
    computed: {
        headers() {
            return this.$_COMPANY_LISTER_HEADERS;
        },
        headingText() {
            return (heading) => {
                if (heading === 'auxiliaryBusinessName' && !this.headings.includes('company')) {
                    return this.$_COMPANY_LISTER_HEADERS['company']
                }

                return this.$_COMPANY_LISTER_HEADERS[heading]
            }
        },
        hasSearch() {
            return Object.values(this.searchArgs)
                .filter(Boolean)
                .join()
                .length > 2
        },
    },
}
</script>
    
<style scoped>
.logo-outer {
    display: block;
    width: 60px;
    height: 60px;
    padding: 0px;
    border-radius: 0%;
    box-shadow: none;
}

.logo-inner {
    width: 60px;
    height: 60px;
    border-radius: 0%;
    background-size: 100%;
}

.company-lister-master h5 {
    text-align: center;
    margin: 2em 0 !important;
}

.company-lister-search {
    display: flex;
    flex-direction: column;
}

.company-lister-search-title {
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
}

.company-lister-search-inputs {
    display: flex;
    flex-direction: row;
	flex-wrap: wrap;
    gap: 7px;
    margin-bottom: 20px;
}

.company-lister-search-inputs > div {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}
</style>