<template>
<div class="row">
    <template v-for="(row, index) in rows">
        <div 
            :key="'row-'+row.$_rowId"
            :class="colClass">
            <component :class="boxClass(row)"
                :style="boxStyle(row)"
                :is="row.url ? 'a' : 'div'"
                :href="row.url"
                :target="row.url ? '_blank' : ''">
                <template v-if="mode === $_COMPANY_LISTER_MODES.imageBox && !row.serviceUrl">
                    {{ row.auxiliaryBusinessName ? row.auxiliaryBusinessName : row.company }}
                </template>
                <template v-else-if="mode === $_COMPANY_LISTER_MODES.imageDescriptionBox">
                    <img v-if="row.serviceUrl" :src="row.serviceUrl" class="img-responsive" />
                    <template v-else>
                        {{ row.auxiliaryBusinessName ? row.auxiliaryBusinessName : row.company }}
                    </template>
                    <div :style="{
                            'text-align': 'left',
                            'font-size': '14px',
                            padding: '5px 10px'
                        }"
                        :title="row.description">
                        <p class="company-description hidden-xs">
                            {{ row.description.length > maxLength ? row.description.substring(0, maxLength - 3) + "..." : row.description }}
                        </p>
                        <p class="company-description hidden-sm hidden-md hidden-lg">
                            {{ row.description }}
                        </p>
                    </div>                
                </template>
                <template v-else-if="mode === $_COMPANY_LISTER_MODES.titleBox">
                    {{ row.auxiliaryBusinessName ? row.auxiliaryBusinessName : row.company }}
                </template>
            </component>
        </div>
        <div v-if="mode === $_COMPANY_LISTER_MODES.titleBox && (index + 1) % 4 == 0" class="clearfix hidden-xs hidden-sm"></div>
    </template>
</div>
</template>

<script>
export default {
    props: {
        mode: {
            type: Number,
            required: true,
        },
        rows: {
            type: Array,
            required: true,
        },
    },
    data: () => ({
        maxLength: 250,
    }),
    computed: {
        colClass() {
            const mode = this.mode;
            const MODES = this.$_COMPANY_LISTER_MODES;

            if (mode === MODES.imageBox) {
                return 'col-xs-12 col-sm-6 col-md-4 col-lg-4'
            } else if (mode === MODES.imageDescriptionBox) {
                return 'col-xs-12 col-sm-6 col-md-4 col-lg-4'
            } else if (mode === MODES.titleBox) {
                return 'col-xs-12 col-sm-4 col-md-3 col-lg-3'
            }

            return '';
        },
        boxClass() {
            const mode = this.mode;
            const MODES = this.$_COMPANY_LISTER_MODES;

            return (row) => {
                if (mode === MODES.imageBox) {
                    return 'companylister-imagebox'
                } else if (mode === MODES.imageDescriptionBox) {
                    return `companylister-imagebox ${ row.description ? 'with-description' : '' }`
                } else if (mode === MODES.titleBox) {
                    return 'companylister-titlebox'
                }

                return '';
            }
        },
        boxStyle() {
            const mode = this.mode;
            const MODES = this.$_COMPANY_LISTER_MODES;

            return (row) => {
                if (mode === MODES.imageBox) {
                    return {
                        'background-image': `url(${ row.serviceUrl })`,
                        'background-repeat': 'no-repeat',
                        'background-position': 'center'
                    }
                } else if (mode === MODES.imageDescriptionBox && row.description) {
                    return {
                        position: 'relative'
                    }
                }

                return {}
            }
        }
    },
}
</script>